import { render, staticRenderFns } from "./WorkoutDashboard.vue?vue&type=template&id=fc33a87e&scoped=true&"
import script from "./WorkoutDashboard.vue?vue&type=script&lang=ts&"
export * from "./WorkoutDashboard.vue?vue&type=script&lang=ts&"
import style0 from "./WorkoutDashboard.vue?vue&type=style&index=0&id=fc33a87e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fc33a87e",
  null
  
)

export default component.exports