



















































import { Component, Vue } from 'vue-property-decorator';
import { CategoryType, CategoryTypeList, CategoryTypeOptions, WorkoutVideoStatusType, WorkoutVideoStatusList } from '@/constants';
import { Helper } from '@/util';
import BrandStore from '@/store/modules/brand';
import WorkoutStore from '@/store/modules/workout';
import { BrandDocument, WorkoutDocument } from '@/models';

interface IWorkoutDashboardWorkout {
  workoutId: string;
  statusType: string;
  statusLabel: string;
}

interface IWorkoutDashboardBrand {
  name: string;
  workouts: IWorkoutDashboardWorkout[];
}

interface IWorkoutDashboardCategory {
  name: string;
  brands: IWorkoutDashboardBrand[];
}

@Component({
  name: 'WorkoutDashboard',
})
export default class extends Vue {
  private brands: BrandDocument[] = (undefined as unknown) as BrandDocument[];
  private workouts: WorkoutDocument[] = (undefined as unknown) as WorkoutDocument[];
  private categoryTypes: Record<CategoryType, string> = (undefined as unknown) as Record<CategoryType, string>;
  private maxWorkoutSequence: number = (undefined as unknown) as number;
  private results: IWorkoutDashboardCategory[] = (undefined as unknown) as IWorkoutDashboardCategory[];

  public created() {
    this.brands = BrandStore.list;
    this.workouts = WorkoutStore.list;
    this.categoryTypes = CategoryTypeList;
    this.maxWorkoutSequence = 0;
    this.results = CategoryTypeOptions.reduce((accumulator, category) => {
      const brands = this.brands.filter(x => x.category === category.key);

      if (brands.length === 0) {
        return accumulator;
      }

      return [
        ...accumulator,
        {
          name: category.label,
          brands: brands
            .sort((a, b) => a.name.localeCompare(b.name))
            .map(brand => {
              const workouts = this.workouts
                .filter(x => x.workoutBrandId === brand.id)
                .sort((a, b) => a.sequence - b.sequence)
                .map(workout => {
                  const statusType = workout.productTypeWorkouts.reduce((statusAccumulator, setup) => {
                    if (setup.isDisabled || statusAccumulator === setup.status || statusAccumulator === WorkoutVideoStatusType.NOT_READY) {
                      return statusAccumulator;
                    } else if (
                      !!setup.productionFile?.key &&
                      [WorkoutVideoStatusType.APPROVED, WorkoutVideoStatusType.READY_TO_APPROVE].includes(setup.status)
                    ) {
                      return setup.status;
                    } else {
                      return WorkoutVideoStatusType.NOT_READY;
                    }
                  }, (null as unknown) as WorkoutVideoStatusType);

                  return {
                    workoutId: workout.id,
                    statusType: WorkoutVideoStatusList[statusType].type as string,
                    statusLabel: WorkoutVideoStatusList[statusType].label as string,
                  };
                });

              this.maxWorkoutSequence = Math.max(this.maxWorkoutSequence, workouts.length);

              return {
                name: brand.name,
                workouts,
              };
            }),
        },
      ];
    }, [] as IWorkoutDashboardCategory[]);
  }

  private handleBrandChange(brandId: string): void {
    Helper.routeTo({ name: 'workout-list', params: { id: brandId } });
  }

  private gotoWorkoutVideo(workoutId: string): void {
    Helper.routeTo({ name: 'workout-update', params: { id: workoutId }, query: { step: 'workoutVideo' } });
  }
}
