



































import { Component } from 'vue-property-decorator';
import WorkoutBaseExercise from './WorkoutBaseExercise';

@Component({
  name: 'WorkoutSummary',
})
export default class extends WorkoutBaseExercise {
  protected uniqueProductTypesColLength: number = (0 as unknown) as number;

  protected created() {
    this.baseCreate().then(() => {
      this.uniqueProductTypesColLength = Math.floor(23 / this.uniqueProductTypeExercises.length);
    });
  }
}
