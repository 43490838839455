



























import { Component, Prop, Vue } from 'vue-property-decorator';
import { WorkoutMovementType } from '.';
import { WorkoutDocument, IWorkoutBrandProductType, BrandDocument } from '@/models';
import BrandStore from '@/store/modules/brand';
import { ProductType, ProductTypeList, ProductTypeOptions } from '@/constants';
import WorkoutStore from '@/store/modules/workout';

@Component({
  name: 'WorkoutProductType',
  components: {
    WorkoutMovementType,
  },
})
export default class extends Vue {
  @Prop({ required: true }) private productType!: ProductType;

  private workout: WorkoutDocument = WorkoutStore.current as WorkoutDocument;
  private setup: IWorkoutBrandProductType = this.workout.productTypeWorkouts.find(x => x.productType === this.productType) as IWorkoutBrandProductType;

  get productTypeName(): string {
    return ProductTypeList[this.setup.productType];
  }

  get parentProductName(): string {
    const parentProductType: ProductType = this.workout.getParentProductType(this.productType) as ProductType;

    if (!parentProductType) {
      return '';
    }

    return ProductTypeList[parentProductType];
  }

  get isDisabled(): boolean {
    return !!this.setup.isDisabled;
  }

  showDisabledOption(): boolean {
    return !!ProductTypeOptions.find(x => x.key === this.productType)?.isOptional;
  }

  showInheritanceOption(): boolean {
    return !!this.workout.getParentProductType(this.productType);
  }

  canInherit(): boolean {
    const brand: BrandDocument = BrandStore.list.find(x => x.id === this.workout.workoutBrandId) as BrandDocument;
    const movementTypeStatusMap = this.workout.getMovementTypeStatus(this.productType, brand);

    /**
     * @TODO Really need a formal way for movement type status
     */
    return !movementTypeStatusMap.some(x => x.startsWith('cannot-'));
  }
}
