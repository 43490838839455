


















































































import { Component, Vue } from 'vue-property-decorator';
import { Helper } from '@/util';
import { WorkoutDocument, BrandDocument, WorkoutModel, IWorkoutBrandProductTypeExercise, ExerciseDocument } from '@/models';
import { CategoryType, CategoryTypeList, ProductType, ProductTypeList } from '@/constants';
import { escapeArray } from '@/util/csv';
import { saveAs } from 'file-saver';

import BrandStore from '@/store/modules/brand';
import WorkoutStore from '@/store/modules/workout';

interface IWorkoutCompareSearch {
  category: CategoryType | null;
  brand: string | null;
  workout: string | null;
  selectedWorkout: WorkoutDocument | null;
  workoutLabel: string;
  showSearch: boolean;
  proxyValues: {
    category: CategoryType | null;
    brand: string | null;
    workout: string | null;
  };
}

interface IWorkoutCompareResult {
  productType: string;
  exercise1: ExerciseDocument | null;
  exercise2: ExerciseDocument | null;
  exercise3: ExerciseDocument | null;
  exercise4: ExerciseDocument | null;
  exercise5: ExerciseDocument | null;
  exercise6: ExerciseDocument | null;
  exercise7: ExerciseDocument | null;
}

@Component({
  name: 'WorkoutCompare',
})
export default class extends Vue {
  private comparisonAmount = 7;
  private productTypes: Record<ProductType, string> = (undefined as unknown) as Record<ProductType, string>;
  private categories: Record<CategoryType, string> = (undefined as unknown) as Record<CategoryType, string>;
  private brands: BrandDocument[] = (undefined as unknown) as BrandDocument[];
  private workouts: WorkoutDocument[] = (undefined as unknown) as WorkoutDocument[];
  private comparison: IWorkoutCompareSearch[] = [];
  private results: IWorkoutCompareResult[] = [];
  private isDownloading: boolean = false;

  private created() {
    this.productTypes = ProductTypeList;
    this.categories = CategoryTypeList;
    this.brands = BrandStore.list;
    this.workouts = WorkoutStore.list;
  }

  private canAddWorkouts(): boolean {
    return this.comparison.length < this.comparisonAmount;
  }

  private addWorkout(): void {
    this.comparison.push({
      category: null,
      brand: null,
      workout: null,
      selectedWorkout: null,
      workoutLabel: '',
      showSearch: false,
      proxyValues: {
        category: null,
        brand: null,
        workout: null,
      },
    });

    this.openSearch(this.comparison.length - 1);
  }

  private getColSpan(delta: number = 0): number {
    return Math.round((24 - delta) / this.comparisonAmount);
  }

  private openSearch(index: number): void {
    (Object.keys(this.comparison[index].proxyValues) as Array<keyof IWorkoutCompareSearch['proxyValues']>).forEach(
      x => (this.comparison[index].proxyValues[x] = this.comparison[index][x] as any),
    );

    this.comparison[index].showSearch = true;
  }

  private async saveSearch(index: number): Promise<void> {
    (Object.keys(this.comparison[index].proxyValues) as Array<keyof IWorkoutCompareSearch['proxyValues']>).forEach(
      x => (this.comparison[index][x] = this.comparison[index].proxyValues[x] as any),
    );

    const selectedBrand: BrandDocument = BrandStore.list.find(x => x.id === this.comparison[index].brand) as BrandDocument;

    this.comparison[index].selectedWorkout = this.workouts.find(x => x.id === this.comparison[index].workout) as WorkoutDocument;
    this.comparison[index].workoutLabel = `Workout #${this.comparison[index].selectedWorkout?.sequence} (${selectedBrand.name})`;

    const selectedExercises: Record<string, ExerciseDocument> = (
      await Promise.all(this.comparison.filter(x => x.workout).map(x => WorkoutModel.getExercises(x.workout as string)))
    )
      .flat()
      .reduce((accumulator, exercise) => ({ ...accumulator, [exercise.id]: exercise }), {});

    this.results = Object.entries(ProductTypeList).reduce((accumulator, [productType, productTypeLabel]) => {
      const maxExercises = this.comparison.reduce(
        (max, comparison) =>
          Math.max(max, comparison.selectedWorkout?.productTypeWorkouts.find(x => x.productType === productType)?.exerciseSetup.length as number),
        0,
      );

      const comparisonMap: Array<IWorkoutBrandProductTypeExercise[]> = this.comparison.map(
        (x: IWorkoutCompareSearch) =>
          x.selectedWorkout?.productTypeWorkouts.find(x => x.productType === productType)?.exerciseSetup as IWorkoutBrandProductTypeExercise[],
      );

      return [
        ...accumulator,
        ...Array(maxExercises)
          .fill(0)
          .reduce(
            (acc, _ignore, index) => [
              ...acc,
              {
                productType: productTypeLabel,
                ...comparisonMap.reduce(
                  (struct, workoutSetup, structIndex) => ({
                    ...struct,
                    [`exercise${structIndex + 1}`]: workoutSetup[index] ? selectedExercises[workoutSetup[index].exerciseId] || null : null,
                  }),
                  {},
                ),
              },
            ],
            [] as IWorkoutCompareResult[],
          ),
      ];
    }, [] as IWorkoutCompareResult[]);

    this.closeSearch(index);
  }

  private closeSearch(index: number): void {
    this.comparison[index].showSearch = false;
  }

  private handleCloseSearch(index: number): void {
    if (!this.comparison[index].selectedWorkout) {
      this.deleteSearch(index);
    }
  }

  private deleteSearch(index: number): void {
    if (this.comparison[index]) {
      this.comparison.splice(index, 1);
    }
  }

  private handleResultSpan(setup: any): number[] | void {
    if (setup.columnIndex > 0) {
      return;
    } else if (setup.rowIndex > 0 && this.results[setup.rowIndex].productType === this.results[setup.rowIndex - 1].productType) {
      return [0, 0];
    }

    const productType: string = this.results[setup.rowIndex].productType;
    const index = this.results.slice(setup.rowIndex).reduce((accumulator, row) => (accumulator += row.productType === productType ? 1 : 0), 0);

    return [index, 1];
  }

  private canSaveSearch(index: number): boolean {
    return !!this.comparison[index].proxyValues.workout;
  }

  private handleFilterSelect(index: number, field: 'category' | 'brand'): void {
    if (field === 'category') {
      this.comparison[index].proxyValues.brand = null;
    }

    this.comparison[index].proxyValues.workout = null;
  }

  private isBrandsEnabled(index: number): boolean {
    return !!this.comparison[index].proxyValues.category;
  }

  private getSelectedBrands(index: number): BrandDocument[] {
    if (!this.isBrandsEnabled(index)) {
      return [];
    }

    return this.brands.filter(x => x.category === this.comparison[index].proxyValues.category);
  }

  private isWorkoutsEnabled(index: number): boolean {
    return this.isBrandsEnabled(index) && !!this.comparison[index].proxyValues.brand;
  }

  private getSelectedWorkouts(index: number): WorkoutDocument[] {
    if (!this.isWorkoutsEnabled(index)) {
      return [];
    }

    return this.workouts.filter(x => x.workoutBrandId === this.comparison[index].proxyValues.brand).sort((a, b) => a.sequence - b.sequence);
  }

  private canShowComparison(): boolean {
    return this.comparison.length > 0;
  }

  private gotoExercise(exercise: ExerciseDocument): void {
    window.open(Helper.routeHref({ name: 'exercise-update', params: { id: exercise.id } }), '_blank');
  }

  private downloadCSV(): void {
    this.isDownloading = true;

    const data = [['Equipment', 'Exercise', 'Workout']]
      .concat(
        this.results
          .reduce((accumulator, result) => {
            this.comparison.forEach((compare, index) => {
              accumulator[index] = [
                ...(accumulator[index] || []),
                [
                  result.productType,
                  (result[`exercise${index + 1}` as keyof IWorkoutCompareResult] as ExerciseDocument | null)?.name || '',
                  compare.workoutLabel,
                ],
              ];
            });

            return accumulator;
          }, [] as string[][][])
          .reduce((accumulator, exercises) => [...accumulator, ...exercises], [] as string[][]),
      )
      .map(escapeArray)
      .join('\n');

    saveAs('data:application/csv;charset=utf-8,' + encodeURIComponent(data), 'workout-comparison.csv');

    this.isDownloading = false;
  }
}
