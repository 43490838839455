import { render, staticRenderFns } from "./WorkoutProductType.vue?vue&type=template&id=234de937&scoped=true&"
import script from "./WorkoutProductType.vue?vue&type=script&lang=ts&"
export * from "./WorkoutProductType.vue?vue&type=script&lang=ts&"
import style0 from "./WorkoutProductType.vue?vue&type=style&index=0&id=234de937&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "234de937",
  null
  
)

export default component.exports