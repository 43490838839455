import { WarmUpCoolDownMovementTypeList, BrandWorkoutSetupType, BrandWorkoutSetupTypeList } from '@/constants';
import { BrandDocument, PartnerBrandDocument, ExerciseDocument, ExerciseModel, QpointModel } from '@/models';
import { CSV } from '@/util';
import { saveAs } from 'file-saver';
import moment from 'moment';

export const brandWarmupCooldown = async (brand: BrandDocument | PartnerBrandDocument): Promise<void> => {
  let maxQPoints = 0;
  const exerciseMap: Record<string, ExerciseDocument> = (await ExerciseModel.find({})).reduce((accumulator, x) => {
    /**
     * While we are here we also find the highest amount of qpoints on an exercise
     */
    maxQPoints = Math.max(maxQPoints, x.qpoints.length);

    return { ...accumulator, [x.id]: x };
  }, {});
  const qpointMap: Record<string, string> = (await QpointModel.find({})).reduce((accumulator, x) => ({ ...accumulator, [x.code]: x.label }), {});

  const columnMap: Record<string, string> = {
    type: 'Type',
    pos: 'Position',
    movementType: 'Movement Type',
    exercise: 'Exercise',
    duration: 'Duration (sec)',
    ...new Array(maxQPoints).fill('').reduce(
      (accumulator: Record<string, string>, _val: any, key: number) => ({
        ...accumulator,
        [`qp${key + 1}Code`]: `QP${key + 1}`,
        [`qp${key + 1}Text`]: 'Text',
      }),
      {},
    ),
  };

  const results = (Object.keys(BrandWorkoutSetupTypeList) as BrandWorkoutSetupType[]).reduce((accumulator, workoutSetupType) => {
    const workoutSetup = brand.workoutSetup.find(x => x.type === workoutSetupType);

    if (!workoutSetup) {
      return accumulator;
    }

    return [
      ...accumulator,
      ...workoutSetup.setup.map((x, k) => {
        const exercise = exerciseMap[x.exerciseId] as ExerciseDocument;
        return {
          type: BrandWorkoutSetupTypeList[workoutSetupType],
          pos: k + 1,
          movementType: WarmUpCoolDownMovementTypeList[x.movementType],
          exercise: exercise.name,
          duration: x.duration,
          ...exercise.qpoints.concat(new Array(maxQPoints - exercise.qpoints.length).fill('')).reduce(
            (pointAccumulator, point, index) => ({
              ...pointAccumulator,
              [`qp${index + 1}Code`]: point,
              [`qp${index + 1}Text`]: point ? qpointMap[point] : '',
            }),
            {},
          ),
        };
      }),
    ];
  }, [] as any[]);

  const csvStr: string = CSV.fromArray(results, columnMap);

  saveAs('data:application/csv;charset=utf-8,' + encodeURIComponent(csvStr), `${brand.name}-warmup-cooldown-${moment().format('YYYYMMDD')}.csv`);
};
