













































































import { Component, Watch } from 'vue-property-decorator';
import { ProductType, StatusType, WorkoutVideoStatusList, WorkoutVideoStatusType } from '@/constants';
import { IWorkoutBrandProductType } from '@/models';
import WorkoutBaseExercise from './WorkoutBaseExercise';

let watchLock: boolean = false;

@Component({
  name: 'WorkoutVideo',
})
export default class extends WorkoutBaseExercise {
  private expandedProductTypes: string[] = ([] as unknown) as ProductType[];
  private workoutVideoStatusList: Record<WorkoutVideoStatusType, StatusType<WorkoutVideoStatusType>> = WorkoutVideoStatusList;

  private created(): void {
    this.baseCreate().then(() => {
      const query = this.$router.currentRoute.query;

      if (query.productType) {
        this.expandedProductTypes = [query.productType as string];
      } else {
        this.expandedProductTypes = [this.uniqueProductTypeExercises[0].productTypes[0]];
      }
    });
  }

  /**
   * @TODO All this needs to be in the model
   */
  private handleFileRemove(productType: ProductType): void {
    const setup = this.workout.productTypeWorkouts.find(x => x.productType === productType);

    if (setup?.status !== WorkoutVideoStatusType.READY_TO_APPROVE) {
      return;
    }

    /**
     * Wait a little bit for the workout change below to finish up
     */
    setTimeout(() => {
      setup.status = WorkoutVideoStatusType.READY_TO_FILM;
    }, 10);
  }

  @Watch('workout', { deep: true })
  onWorkoutChange(): void {
    if (watchLock) {
      return;
    }

    /**
     * We need to update any child that inherit to reflect its inherited parent, so basically keep them insync
     */
    this.uniqueProductTypeExercises.forEach(setup => {
      setup.productTypes.slice(0).forEach(productType => {
        const childSetup = this.workout.productTypeWorkouts.find(x => x.productType === productType) as IWorkoutBrandProductType;

        childSetup.productionFile = setup.primary.productionFile;
        childSetup.status = setup.primary.status;
      });
    });

    /**
     * Use setTimeout to turn off locking as a change detection will trigger off the watched AFTER the initial watcher
     * call is triggered, so stuck in a loop
     */
    setTimeout(() => {
      watchLock = false;
    });
  }
}
