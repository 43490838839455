































import { Component, Vue, Watch } from 'vue-property-decorator';
import { Helper, IStepSetup } from '@/util';
import {
  BrandDocument,
  WorkoutModel,
  WorkoutDocument,
  IWorkout,
  IWorkoutBrandProductTypeOption,
  IWorkoutBrandProductType,
  IWorkoutBrandProductTypeExercise,
} from '@/models';
import { ProductType, ProductTypeList } from '@/constants';
import BrandStore from '@/store/modules/brand';
import { WorkoutProductType, WorkoutSummary, WorkoutVideo } from '@/views/workouts/sections';
import WorkoutStore from '@/store/modules/workout';

interface IWorkoutStep extends IStepSetup {
  isProduct: boolean;
  status: string;
}

interface IWorkoutChange {
  productType: ProductType | null;
  exclude: boolean;
}

let watchLock: boolean = false;
let oldWorkout: IWorkout = (undefined as unknown) as IWorkout;

@Component({
  name: 'WorkoutManage',
})
export default class extends Vue {
  private workout: WorkoutDocument = WorkoutStore.current as WorkoutDocument;
  private rules = Helper.schemaToRules(WorkoutModel.getSchema());
  private step = 0;
  private steps: Array<IWorkoutStep> = (undefined as unknown) as Array<IWorkoutStep>;
  private resyncProductTypeInheritenceMapping: boolean = false;

  private selectedBrand: BrandDocument = (undefined as unknown) as BrandDocument;

  public async created() {
    this.selectedBrand = BrandStore.list.find(brand => brand.id === this.workout.workoutBrandId) as BrandDocument;

    this.resyncProductTypeInheritenceMapping = this.workout.isNew();

    this.steps = this.workout.productTypeWorkouts.map(productTypeSetup => ({
      id: productTypeSetup.productType,
      isProduct: true,
      label: ProductTypeList[productTypeSetup.productType],
      status: '',
      component: WorkoutProductType,
    }));

    this.steps.push({
      id: 'workoutSummary',
      isProduct: false,
      label: 'Summary',
      status: '',
      component: WorkoutSummary,
    });

    this.steps.push({
      id: 'workoutVideo',
      isProduct: false,
      label: 'Video',
      status: '',
      component: WorkoutVideo,
    });

    const query = this.$router.currentRoute.query;
    const stepId: string = query.step as string;
    if (stepId) {
      this.gotoStep(stepId);
    }
  }

  @Watch('workout', { immediate: true, deep: true })
  private onWorkoutChange(newWorkout: WorkoutDocument): void {
    if (!oldWorkout) {
      oldWorkout = newWorkout.toData() as IWorkout;
      return;
    }

    if (watchLock) {
      return;
    }

    /**
     * See if anything of the inheritance or exercises have changed in this workout change. If so then resync the
     * inheritance mapping
     */
    const changeSetup: IWorkoutChange = newWorkout.productTypeWorkouts.reduce(
      (accumulator, newProductTypeWorkout: IWorkoutBrandProductType, index) => {
        if (accumulator.productType) {
          return accumulator;
        }

        const oldProductTypeWorkout: IWorkoutBrandProductType = oldWorkout.productTypeWorkouts[index];

        if (newProductTypeWorkout.isInherited !== oldProductTypeWorkout.isInherited) {
          return {
            productType: newProductTypeWorkout.productType,
            exclude: false,
          };
        }

        const exercisesChanged = newProductTypeWorkout.exerciseSetup.some(
          (newMovementTypeSetup: IWorkoutBrandProductTypeExercise, exerciseIndex) =>
            newMovementTypeSetup.exerciseId !== oldProductTypeWorkout.exerciseSetup[exerciseIndex].exerciseId,
        );

        if (exercisesChanged) {
          return {
            productType: newProductTypeWorkout.productType,
            exclude: true,
          };
        }

        return accumulator;
      },
      { productType: null, exclude: false } as IWorkoutChange,
    );

    oldWorkout = newWorkout.toData() as IWorkout;

    /**
     * If changed then resync the product type inheritance mapping
     */
    if (changeSetup.productType) {
      watchLock = true;

      /**
       * If resyncProductTypeInheritenceMapping is false then just resync the isInherited property, else resync the exercise inheritence
       * mapping
       */
      if (!this.resyncProductTypeInheritenceMapping) {
        this.workout.syncInheritence();
      } else {
        const exerciseOptions: IWorkoutBrandProductTypeOption[] = WorkoutStore.exerciseOptions as IWorkoutBrandProductTypeOption[];

        this.workout.syncProductTypeWorkouts(exerciseOptions, changeSetup.productType, changeSetup.exclude);
      }

      /**
       * Use setTimeout to turn off locking as a change detection will trigger off the watched AFTER the initial watcher
       * call is triggered, so stuck in a loop
       */
      setTimeout(() => {
        console.log('Unset watchLock');
        watchLock = false;
      });
    }
  }

  get title(): string {
    return this.workout.isNew() ? 'Create Workout' : `Workout ${this.workout.sequence}`;
  }

  get component(): any {
    const step = this.getStep();
    if (step.component) {
      return step.component;
    }
    return null;
  }

  private gotoStep(stepId: string, exerciseIndex: number = -1) {
    const stepIndex = this.steps.findIndex(step => {
      return step.id === stepId;
    });
    if (stepIndex > -1) {
      this.step = stepIndex;
    }
    if (exerciseIndex > -1) {
      setTimeout(() => {
        const refName = `${stepId}-exercise-${exerciseIndex}`;
        const element = document.getElementById(refName);
        element && element.scrollIntoView();
      }, 200);
    }
  }

  private getStep(step: number = this.step): IWorkoutStep {
    return this.steps[step];
  }

  public showNextStep(): boolean {
    return this.step + 1 < this.steps.length;
  }

  public nextStep(): void {
    const { step, steps } = this;
    if (step + 1 < steps.length) {
      this.step = step + 1;
    }
  }

  public async disableWorkout(): Promise<void> {
    let confirmed = false;

    try {
      await this.$confirm('Are you sure you wish to delete this workout?', 'Delete Workout', { type: 'error' });
      confirmed = true;
    } catch (e) {
      confirmed = false;
    }

    if (confirmed) {
      await this.workout.disable();

      await Helper.routeTo({ name: 'workout-list', params: { id: this.workout.workoutBrandId } });
    }
  }

  public async saveForm(): Promise<void> {
    try {
      const isNew = this.workout.isNew();

      await this.workout.validate();

      await this.workout.save();

      await Helper.routeTo({ name: 'workout-list', params: { id: this.workout.workoutBrandId } });

      this.$message({
        message: `Workout successfully ${isNew ? 'created' : 'updated'}.`,
        type: 'success',
      });
    } catch (e) {
      console.error('ERROR', e);
    }
  }

  public async cancelForm(): Promise<void> {
    await Helper.routeTo({ name: 'workout-list', params: { id: this.workout.workoutBrandId } });
  }

  public prevStep(): void {
    const { step } = this;
    if (step - 1 >= 0) {
      this.step = step - 1;
    }
  }
}
