import { Component, Vue } from 'vue-property-decorator';
import { FileUploader, VideoViewer } from '@/components';
import { ExerciseVideoStatusList, ExerciseVideoStatusType, ProductType, ProductTypeList, ProductTypeOptions, StatusType } from '@/constants';
import { ExerciseDocument, ExerciseModel, WorkoutDocument, IWorkoutBrandProductType } from '@/models';
import WorkoutStore from '@/store/modules/workout';
import { routeHref } from '@/util/helpers';
import { IS3Object } from '@/util';
import { Storage } from 'aws-amplify';
import { saveAs } from 'file-saver';

interface IUniqueProductTypeExercises {
  primary: IWorkoutBrandProductType;
  productTypes: ProductType[];
  productTypeNames: string[];
  exercises: Array<ExerciseDocument | null>;
  videos: Array<IS3Object | null>;
}

@Component({
  name: 'WorkoutBaseExercise',
  components: {
    FileUploader,
    VideoViewer,
  },
})
export default class extends Vue {
  protected workout: WorkoutDocument = WorkoutStore.current as WorkoutDocument;
  protected uniqueProductTypeExercises: IUniqueProductTypeExercises[] = (null as unknown) as IUniqueProductTypeExercises[];
  protected uniqueProductTypeExerciseLength: number = (null as unknown) as number;
  protected exercisesLoading: boolean = false;
  protected showVideo: boolean = false;
  protected videoSrc: string = '';
  protected videoMimetype: string = '';
  protected exerciseVideoStatusList: Record<ExerciseVideoStatusType, StatusType<ExerciseVideoStatusType>> = ExerciseVideoStatusList;

  protected async baseCreate() {
    this.exercisesLoading = true;

    /**
     * Load the exercises
     */
    const exerciseIdList: string[] = this.workout.productTypeWorkouts.reduce((accumulator, productTypeWorkout) => {
      return [...accumulator, ...productTypeWorkout.exerciseSetup.map(exerciseSetup => exerciseSetup.exerciseId)];
    }, [] as string[]);

    const query = { or: [...new Set(exerciseIdList)].map(x => ({ id: { eq: x } })) };

    const workoutExercises: Record<string, ExerciseDocument> = (await ExerciseModel.find(query)).reduce(
      (accumulator, exercise) => ({ ...accumulator, [exercise.id]: exercise }),
      {} as Record<string, ExerciseDocument>,
    );

    /**
     * Construct the data
     */
    this.uniqueProductTypeExerciseLength = 0;
    this.uniqueProductTypeExercises = this.workout.productTypeWorkouts.reduce((accumulator, productTypeWorkout, index) => {
      if (productTypeWorkout.isDisabled) {
        return accumulator;
      }

      let newEntry = true;
      const productTypeSetup = ProductTypeOptions.find(x => x.key === productTypeWorkout.productType);

      if (!productTypeSetup?.isOptional && accumulator.length > 0) {
        const prevProductTypeWorkout = this.workout.getParentProductTypeSetup(this.workout.productTypeWorkouts[index].productType) as IWorkoutBrandProductType;

        newEntry = !productTypeWorkout.exerciseSetup.every((x, k) => prevProductTypeWorkout.exerciseSetup[k].exerciseId === x.exerciseId);
      }

      if (newEntry) {
        accumulator.push({
          primary: productTypeWorkout,
          productTypes: [productTypeWorkout.productType],
          productTypeNames: [ProductTypeList[productTypeWorkout.productType]],

          /**
           * Get the exercise and its video
           */
          ...productTypeWorkout.exerciseSetup.reduce(
            (exerciseAccumulator, exerciseSetup) => {
              const exercise = workoutExercises[exerciseSetup.exerciseId];

              if (!exercise) {
                (exerciseAccumulator.exercises as Array<ExerciseDocument | null>).push(null);
                (exerciseAccumulator.statuses as Array<StatusType<ExerciseVideoStatusType> | null>).push(null);
                (exerciseAccumulator.videos as Array<IS3Object | null>).push(null);

                return exerciseAccumulator;
              }

              /**
               * Because we allow brands to be unassociated from an exercise when they are currently being used in a workout, we have to
               * check for that aswell
               */
              const brandData = exercise.brandSetup.find(brand => brand.brandId === this.workout.workoutBrandId);

              if (!brandData) {
                (exerciseAccumulator.exercises as Array<ExerciseDocument | null>).push(null);
                (exerciseAccumulator.statuses as Array<StatusType<ExerciseVideoStatusType> | null>).push(null);
                (exerciseAccumulator.videos as Array<IS3Object | null>).push(null);

                return exerciseAccumulator;
              }

              const status: StatusType<ExerciseVideoStatusType> = this.exerciseVideoStatusList[brandData.status];
              let videoSetup = null;

              if (brandData.processedFile && brandData.processedFile.key !== '') {
                videoSetup = brandData.processedFile;
              }

              (exerciseAccumulator.exercises as Array<ExerciseDocument | null>).push(exercise);
              (exerciseAccumulator.statuses as Array<StatusType<ExerciseVideoStatusType> | null>).push(status);
              (exerciseAccumulator.videos as Array<IS3Object | null>).push(videoSetup);

              return exerciseAccumulator;
            },
            { exercises: [], statuses: [], videos: [] },
          ),
        });

        this.uniqueProductTypeExerciseLength = Math.max(this.uniqueProductTypeExerciseLength, accumulator[accumulator.length - 1].exercises.length);
      } else {
        accumulator[accumulator.length - 1].productTypes.push(productTypeWorkout.productType);
        accumulator[accumulator.length - 1].productTypeNames.push(ProductTypeList[productTypeWorkout.productType]);
      }

      return accumulator;
    }, [] as IUniqueProductTypeExercises[]);

    this.exercisesLoading = false;
  }

  protected sameExerciseAsParent(productType: ProductType, exerciseSetupIndex: number): boolean {
    const productTypeWorkoutIndex = this.workout.productTypeWorkouts.findIndex(x => x.productType === productType);
    const productTypeWorkout = this.workout.productTypeWorkouts[productTypeWorkoutIndex];

    if (productTypeWorkoutIndex === 0 || !productTypeWorkout.exerciseSetup[exerciseSetupIndex]) {
      return true;
    }

    const parentProductTypeWorkout = this.workout.getParentProductTypeSetup(
      this.workout.productTypeWorkouts[productTypeWorkoutIndex].productType,
    ) as IWorkoutBrandProductType;

    if (!parentProductTypeWorkout.exerciseSetup[exerciseSetupIndex]) {
      return false;
    }

    return parentProductTypeWorkout.exerciseSetup[exerciseSetupIndex].exerciseId === productTypeWorkout.exerciseSetup[exerciseSetupIndex].exerciseId;
  }

  protected gotoExercise(exercise: ExerciseDocument): void {
    const href = routeHref({
      name: 'exercise-update',
      params: { id: exercise.id },
      query: { step: 'video', selectBrand: this.workout.workoutBrandId },
    });

    window.open(href, '_blank');
  }

  protected getExerciseVideo(exercise: ExerciseDocument): IS3Object | undefined {
    const brandId = this.workout.workoutBrandId;
    const brandSetup = exercise.brandSetup;
    const brandData = brandSetup.find(brand => brand.brandId === brandId);

    if (!brandData) {
      return;
    }

    if (brandData.processedFile && brandData.processedFile.key !== '') {
      return brandData.processedFile;
    }
  }

  protected async downloadVideo(exercise: ExerciseDocument): Promise<void> {
    const videoSetup = this.getExerciseVideo(exercise);

    if (!videoSetup) {
      return;
    }

    const result = (await Storage.get(videoSetup.key, { download: true })) as Record<string, any>;

    saveAs(result.Body as Blob, videoSetup.name);
  }

  protected async playVideo(exercise: ExerciseDocument): Promise<void> {
    const videoSetup = this.getExerciseVideo(exercise);

    if (!videoSetup) {
      return;
    }

    this.videoSrc = (await Storage.get((videoSetup as IS3Object).key)) as string;
    this.videoMimetype = videoSetup.mimetype;
    this.showVideo = true;
  }

  protected stopVideo(): void {
    this.showVideo = false;
  }
}
