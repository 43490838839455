























































































































































import { Component, Vue } from 'vue-property-decorator';
import { GreyBox } from '@/components';
import { VideoViewer } from '@/components';

import {
  CategoryType,
  CategoryTypeList,
  ProductType,
  ProductTypeList,
  StatusType,
  WorkoutVideoStatusType,
  WorkoutVideoStatusList,
  ExerciseVideoStatusType,
  ExerciseVideoStatusList,
  BrandWorkoutSetupType,
  BrandWorkoutSetupTypeList,
} from '@/constants';
import BrandStore from '@/store/modules/brand';
import { BrandDocument, WorkoutDocument, WorkoutModel, IWorkoutBrandProductType, ExerciseModel, ExerciseDocument } from '@/models';
import { Helper, CSV } from '@/util';
import { CSV as sharedCSV } from '@/shared';
import { saveAs } from 'file-saver';
import { canDownloadCSV } from './util';
import { S3ObjectInput } from '@/API';
import { Storage } from 'aws-amplify';
import moment from 'moment';

interface IWorkoutSetup {
  type: BrandWorkoutSetupType;
  name: string;
  exercises: ExerciseDocument[];
}

@Component({
  name: 'WorkoutList',
  components: {
    GreyBox,
    VideoViewer,
  },
})
export default class extends Vue {
  private isSearching: boolean = false;
  private results: WorkoutDocument[] = [];
  private workoutSetup: IWorkoutSetup[] = ([] as unknown) as IWorkoutSetup[];
  private selectedBrand: string | null = null;
  private currentBrand: BrandDocument = (undefined as unknown) as BrandDocument;
  private lastBrandSearched: string | null = null;
  private brands: BrandDocument[] = (undefined as unknown) as BrandDocument[];
  private categoryTypes: Record<CategoryType, string> = (undefined as unknown) as Record<CategoryType, string>;
  private productTypes: Record<ProductType, string> = (undefined as unknown) as Record<ProductType, string>;
  private workoutVideoStatusList: Record<WorkoutVideoStatusType, StatusType<WorkoutVideoStatusType>> = (undefined as unknown) as Record<
    WorkoutVideoStatusType,
    StatusType<WorkoutVideoStatusType>
  >;
  private exerciseVideoStatusList: Record<ExerciseVideoStatusType, StatusType<ExerciseVideoStatusType>> = (undefined as unknown) as Record<
    ExerciseVideoStatusType,
    StatusType<ExerciseVideoStatusType>
  >;

  private productTypeKeys: string[] = [] as string[];
  private canDownloadCSV: boolean = (undefined as unknown) as boolean;
  private showVideo: Record<string, boolean> = {};
  private videoUrl: Record<string, string> = {};

  private downloading = {
    main: false,
    prodTeam: false,
    warmupCooldown: false,
  };

  public created() {
    this.brands = BrandStore.list;
    this.categoryTypes = CategoryTypeList;
    this.productTypes = ProductTypeList;
    this.workoutSetup = [];
    this.workoutVideoStatusList = WorkoutVideoStatusList;
    this.exerciseVideoStatusList = ExerciseVideoStatusList;
    this.canDownloadCSV = canDownloadCSV;
    this.requestSearch();
  }

  private requestSearch() {
    const brandId = this.$router.currentRoute.params.id || this.selectedBrand;
    if (!brandId) {
      return;
    }
    this.selectedBrand = brandId;
    if (this.lastBrandSearched === brandId) {
      return;
    }

    this.lastBrandSearched = brandId;
    this.doSearch(this.selectedBrand);
    const brand = this.brands.find(brand => brand.id === this.selectedBrand);
    if (!brand) {
      throw new Error(`Selected brand ${this.selectedBrand} not found`);
    }
    this.currentBrand = brand;
    this.doWorkoutSetupSearch();
  }

  get brandName() {
    if (this.currentBrand) {
      return this.currentBrand.name;
    }
    return '';
  }

  private getExerciseBrand(exercise: ExerciseDocument) {
    if (!this.selectedBrand) {
      return '';
    }
    if (!exercise) {
      return '';
    }

    const brandType = exercise.brandSetup.find(brand => brand.brandId === this.selectedBrand);
    if (brandType) {
      return brandType;
    }
    throw new Error(`Brand ${this.selectedBrand} does not exist in exercise ${exercise.id}`);
  }

  private toggleShowVideo(exerciseKey: string) {
    const state = this.showVideo[exerciseKey] || false;
    this.showVideo[exerciseKey] = !state;
    this.$forceUpdate();
  }
  private async loadExerciseVideoUrl(file: S3ObjectInput, exerciseKey: string) {
    const fileUrl = (await Storage.get(file.key)) as string;
    this.videoUrl[exerciseKey] = fileUrl;
    return true;
  }

  private videoApproved(status: ExerciseVideoStatusType) {
    if (!status) {
      return false;
    }
    return this.exerciseVideoStatusList[status].complete;
  }

  private async handleBrandChange(brandId: string) {
    this.gotoWorkoutList(brandId);
  }

  private async doSearch(brandId: string): Promise<void> {
    if (this.isSearching) {
      return;
    }

    this.isSearching = true;

    this.results = await WorkoutModel.findByBrandId(brandId);
    this.results.sort((a, b) => a.sequence - b.sequence);
    this.lastBrandSearched = brandId;
    this.productTypeKeys = this.getUniqueWorkoutProductTypes();
    this.isSearching = false;
  }

  private async doWorkoutSetupSearch(): Promise<void> {
    const exerciseIDList: string[] = this.currentBrand.workoutSetup.reduce(
      (accumulator, setup) => [...accumulator, ...setup.setup.map(x => x.exerciseId)],
      [] as string[],
    );

    if (exerciseIDList.length > 0) {
      const exerciseResults = await ExerciseModel.find(Helper.buildOrQuery('id', exerciseIDList));

      this.workoutSetup = this.currentBrand.workoutSetup.reduce(
        (accumulator, setup) =>
          [
            ...accumulator,
            {
              type: setup.type,
              name: BrandWorkoutSetupTypeList[setup.type],
              exercises: setup.setup.map(x => exerciseResults.find(exercise => exercise.id === x.exerciseId)),
            },
          ] as IWorkoutSetup[],
        [] as IWorkoutSetup[],
      );
    }
  }

  private jsonListQuery(property: string, list: any[]): any {
    const queries = list.map(value => {
      return { [property]: { contains: value } };
    });
    return { or: queries };
  }

  private getUniqueWorkoutProductTypes(): string[] {
    const uniqueProductTypes = new Set<string>();
    for (let i = 0; i < this.results.length; i++) {
      const workout = this.results[i];
      workout.productTypeWorkouts.map(productTypeWorkout => {
        uniqueProductTypes.add(productTypeWorkout.productType);
      });
    }
    return Array.from(uniqueProductTypes) as string[];
  }

  private getStatus(productTypeWorkout: IWorkoutBrandProductType): WorkoutVideoStatusType | null {
    return productTypeWorkout.status;
  }

  private getProductTypeWorkout(workout: WorkoutDocument, productType: string): IWorkoutBrandProductType | null {
    return workout.productTypeWorkouts.find(item => item.productType === productType) || null;
  }

  private gotoWorkoutList(brandId: string) {
    Helper.routeTo({ name: 'workout-list', params: { id: brandId } });
  }

  private gotoWorkoutCreate() {
    Helper.routeTo({ name: 'workout-create', params: { brandId: this.selectedBrand as string } });
  }

  private gotoWorkoutUpdate(workoutId: string, productType?: string) {
    const step = productType as string;
    Helper.routeTo({ name: 'workout-update', params: { id: workoutId }, query: { step } });
  }

  private gotoWorkoutSummary(workoutId: string): void {
    Helper.routeTo({ name: 'workout-update', params: { id: workoutId }, query: { step: 'workoutSummary' } });
  }

  private gotoWorkoutVideo(workoutId: string): void {
    Helper.routeTo({ name: 'workout-update', params: { id: workoutId }, query: { step: 'workoutVideo' } });
  }

  private gotoBrandWarmupCooldown(brandId: string): void {
    Helper.routeTo({ name: 'brand-update', params: { id: brandId, step: 'warmupCooldownExercises' } });
  }

  protected async downloadVideo(exerciseVideo: S3ObjectInput): Promise<void> {
    if (!exerciseVideo) {
      return;
    }

    const result = (await Storage.get(exerciseVideo.key, { download: true })) as Record<string, any>;

    saveAs(result.Body as Blob, exerciseVideo.name);
  }

  private async handleDeleteWorkout(workout: WorkoutDocument) {
    await workout.disable();

    this.$message({
      message: `Workout ${workout.sequence} successfully deleted.`,
      type: 'success',
    });

    await this.doSearch(this.selectedBrand || '');
  }

  private async downloadWarmupCooldownCSV(): Promise<void> {
    this.downloading.warmupCooldown = true;

    await sharedCSV.brandWarmupCooldown(this.currentBrand);

    this.downloading.warmupCooldown = false;
  }

  private async downloadCSV(): Promise<void> {
    this.downloading.main = true;

    /**
     * @TODO Quick hack until we get error checking on the exercise <-> brand <-> workout fixed
     *
     * Get all the exercises used in this brand. Also workout max qpouints while we're here
     */
    let maxQPoints = 0;

    const exercises: Record<string, ExerciseDocument> = (await ExerciseModel.find()).reduce((accumulator, exercise: ExerciseDocument) => {
      maxQPoints = Math.max(maxQPoints, exercise.qpoints.length);
      return { ...accumulator, [exercise.id]: exercise };
    }, {} as Record<string, ExerciseDocument>);

    /**
     * Construct the csv download
     */
    const columnMap: any = this.results.reduce(
      (accumulator, workout) => ({
        ...accumulator,
        [`workout${workout.sequence}ExName`]: `Workout ${workout.sequence}`,
        ...new Array(maxQPoints).fill('').reduce(
          (colAccumulator: Record<string, string>, _val: any, key: number) => ({
            ...colAccumulator,
            [`workout${workout.sequence}QP${key + 1}`]: `QP${key + 1}#`,
          }),
          {},
        ),
      }),
      { name: this.currentBrand.name, exerciseNo: 'Exercise No#' },
    );

    const results: Array<any[] | null> = (Object.keys(ProductType) as ProductType[]).reduce((accumulator, productType: ProductType) => {
      accumulator.push(null);

      const exerciseLength: number = this.results.reduce(
        (lengthAccumulator, workout) =>
          Math.max(lengthAccumulator, (workout.productTypeWorkouts.find(x => x.productType === productType) as IWorkoutBrandProductType).exerciseSetup.length),
        0,
      );

      for (let exerciseIndex = 0; exerciseIndex < exerciseLength; exerciseIndex++) {
        const productExercises = {
          name: ProductTypeList[productType],
          exerciseNo: exerciseIndex + 1,
          ...this.results.reduce((workoutAccumulator, workout) => {
            const prodTypeSetup = workout.productTypeWorkouts.find(x => x.productType === productType && !x.isDisabled) as IWorkoutBrandProductType;

            if (!prodTypeSetup) {
              return workoutAccumulator;
            }

            const exerciseSetup = prodTypeSetup.exerciseSetup[exerciseIndex];

            if (!exerciseSetup) {
              return workoutAccumulator;
            }
            const exercise: ExerciseDocument = exercises[exerciseSetup.exerciseId];

            if (!exercise) {
              return workoutAccumulator;
            }

            workoutAccumulator[`workout${workout.sequence}ExName`] = exercise.name;

            const prevProductTypeSetup = workout.getParentProductTypeSetup(productType);

            if (prevProductTypeSetup) {
              const prevExerciseSetup = prevProductTypeSetup.exerciseSetup;
              if (prevExerciseSetup && exerciseIndex < prevExerciseSetup.length) {
                if (prevExerciseSetup[exerciseIndex].exerciseId !== exerciseSetup.exerciseId) {
                  workoutAccumulator[`workout${workout.sequence}ExChanged`] = 'YES';
                }
              }
            }

            exercise.qpoints.concat(new Array(maxQPoints - exercise.qpoints.length).fill('')).forEach((point, index) => {
              workoutAccumulator[`workout${workout.sequence}QP${index + 1}`] = point;
            });

            return workoutAccumulator;
          }, {} as any),
        };
        accumulator.push(productExercises);
      }

      return accumulator;
    }, [] as Array<any[] | null>);

    const csvStr: string = CSV.fromArray(results, columnMap);

    saveAs('data:application/csv;charset=utf-8,' + encodeURIComponent(csvStr), `${this.currentBrand.name.toLowerCase().replace(/\s+/g, '-')}-workout-list.csv`);

    this.downloading.main = false;
  }

  private async downloadProdTeamCSV(): Promise<void> {
    this.downloading.prodTeam = true;

    const exercises: Record<string, ExerciseDocument> = (await ExerciseModel.find()).reduce(
      (accumulator, exercise) => ({ ...accumulator, [exercise.id]: exercise }),
      {} as Record<string, ExerciseDocument>,
    );

    const workoutCSVs = this.results.map((workout, index) => {
      const workoutCSV: any[][] = [];
      const workoutCSVHeaders: string[] = [];

      const matchingProductTypes = workout.getMatchingProductTypes();

      matchingProductTypes.forEach((matchingSetup, matchingIndex) => {
        let previousMatchingSetup: any = null;

        if (matchingIndex === 0) {
          workoutCSVHeaders.push(`Workout ${index + 1}`);
        } else {
          previousMatchingSetup = matchingProductTypes[matchingIndex - 1];
          workoutCSVHeaders.push('');
        }

        const startingPoint = workoutCSVHeaders.length - 1;

        const maxQPoints = matchingSetup.exerciseSetup.reduce(
          (accumulator: number, exerciseSetup) => Math.max(accumulator, exercises[exerciseSetup.exerciseId].qpoints.length),
          0,
        );

        workoutCSVHeaders.push(matchingSetup.productTypes.map(x => ProductTypeList[x]).join(', '));

        for (let i = 1; i <= maxQPoints; i++) {
          workoutCSVHeaders.push(`Q${i}`);
        }

        if (previousMatchingSetup) {
          workoutCSVHeaders.push('Change');
        }

        matchingSetup.exerciseSetup.forEach((exerciseSetup, exerciseSetupIndex) => {
          if (matchingIndex === 0) {
            workoutCSV.push([]);
          }

          let cellPos = startingPoint;

          workoutCSV[exerciseSetupIndex][cellPos++] = exerciseSetupIndex + 1;
          workoutCSV[exerciseSetupIndex][cellPos++] = exercises[exerciseSetup.exerciseId].name;

          exercises[exerciseSetup.exerciseId].qpoints
            .concat(new Array(maxQPoints - exercises[exerciseSetup.exerciseId].qpoints.length).fill(''))
            .forEach(qpoint => {
              workoutCSV[exerciseSetupIndex][cellPos++] = qpoint;
            });

          if (previousMatchingSetup) {
            const hasChanged =
              !previousMatchingSetup.exerciseSetup[exerciseSetupIndex] ||
              previousMatchingSetup.exerciseSetup[exerciseSetupIndex].exerciseId !== exerciseSetup.exerciseId;

            workoutCSV[exerciseSetupIndex][cellPos++] = hasChanged ? 'Y' : '';
          }
        });
      });

      workoutCSV.unshift(workoutCSVHeaders);

      return workoutCSV.map(CSV.escapeArray).join('\n');
    });

    saveAs(
      'data:application/csv;charset=utf-8,' + encodeURIComponent(workoutCSVs.join('\n\n')),
      `${this.currentBrand.name}-Prod-Team-Extract-${moment().format('YYYYMMDD')}.csv`.replace(/\s+/g, '-'),
    );

    this.downloading.prodTeam = false;
  }
}
