






























import { Component, Prop, Vue } from 'vue-property-decorator';
import { GreyBox } from '@/components';
import {
  WorkoutModel,
  WorkoutDocument,
  IWorkoutBrandProductType,
  IWorkoutBrandProductTypeExercise,
  IWorkoutBrandProductTypeOption,
  IWorkoutBrandProductTypeOptionExercises,
  BrandDocument,
} from '@/models';
import { ProductType, MovementType, MovementTypeList } from '@/constants';
import WorkoutStore from '@/store/modules/workout';
import BrandStore from '@/store/modules/brand';
import { canManage } from '../util';

interface ExerciseOption {
  key: string;
  value: string;
  lower: string;
}

@Component({
  name: 'WorkoutMovementType',
  components: {
    GreyBox,
  },
})
export default class extends Vue {
  @Prop({ required: true }) private productType!: ProductType;
  @Prop({ required: true }) private movementType!: MovementType;
  @Prop({ required: true }) private movementTypeIndex!: number;

  private workout: WorkoutDocument = WorkoutStore.current as WorkoutDocument;
  private selectedExerciseWrapper: string = '';
  private movementTypeSetup: IWorkoutBrandProductTypeExercise = (null as unknown) as IWorkoutBrandProductTypeExercise;
  private movementTypeStatus: string = (null as unknown) as string;
  private movementTypeStatusText: string = (null as unknown) as string;
  private movementTypeStatusClass: string = (null as unknown) as string;
  private exerciseOptions: ExerciseOption[] = (null as unknown) as ExerciseOption[];
  private lastUsed: string = (null as unknown) as string;
  private canManage: boolean = (null as unknown) as boolean;

  get title(): string {
    return `${MovementTypeList[this.movementType]}`;
  }

  get numTitle(): string {
    return `${this.movementTypeIndex + 1}. ${MovementTypeList[this.movementType]}`;
  }

  private created() {
    this.canManage = canManage;

    const productTypeSetup: IWorkoutBrandProductType = this.workout.productTypeWorkouts.find(
      x => x.productType === this.productType,
    ) as IWorkoutBrandProductType;

    this.movementTypeSetup = productTypeSetup.exerciseSetup[this.movementTypeIndex] as IWorkoutBrandProductTypeExercise;

    const productTypeExerciseOptions = (WorkoutStore.exerciseOptions as IWorkoutBrandProductTypeOption[]).find(
      x => x.productType === this.productType,
    ) as IWorkoutBrandProductTypeOption;

    const movementTypeExerciseOptions = productTypeExerciseOptions.exerciseSetup.find(
      x => x.movementType === this.movementType,
    ) as IWorkoutBrandProductTypeOptionExercises;

    this.exerciseOptions = movementTypeExerciseOptions.exercises.map(x => {
      if (x.id === this.movementTypeSetup.exerciseId) {
        this.selectedExerciseWrapper = x.name;
      }

      return {
        key: x.id,
        value: x.name,
        lower: x.name.toLocaleLowerCase(),
      };
    });

    this.movementTypeStatus = this.getMovementTypeStatus();
    this.movementTypeStatusText = '';
    this.movementTypeStatusClass = '';

    switch (this.movementTypeStatus) {
      case 'cannot-inherit-movement-type':
        this.movementTypeStatusText = 'Cannot inherit previous rig/kit movement type';
        this.movementTypeStatusClass = 'unsuitable';
        break;
      case 'cannot-inherit-different-movement-type':
        this.movementTypeStatusText = 'Movement type changed due to no available exercises AND cannot inherit previous rig/kit movement type';
        this.movementTypeStatusClass = 'unsuitable';
        break;
      case 'cannot-inherit-exercise':
        this.movementTypeStatusText = 'Cannot inherit previous rig/kit exercise';
        this.movementTypeStatusClass = 'unsuitable';
        break;
      case 'inherited-different-movement-type':
        this.movementTypeStatusText = 'Movement type changed to match previous parent rig/kit';
        this.movementTypeStatusClass = 'made-suitable';
        break;
      case 'different-movement-type':
        this.movementTypeStatusText = 'Movement type changed due to no available exercises';
        this.movementTypeStatusClass = 'made-suitable';
        break;
      default:
        this.movementTypeStatusClass = 'suitable';
        break;
    }

    this.setLastUsed();
  }

  private getMovementTypeStatus(): string {
    const brand: BrandDocument = BrandStore.list.find(x => x.id === this.workout.workoutBrandId) as BrandDocument;
    const movementTypeStatusMap = this.workout.getMovementTypeStatus(this.productType, brand);

    return movementTypeStatusMap[this.movementTypeIndex];
  }

  private queryExercises(searchStr: string, cb: (results: ExerciseOption[]) => void): void {
    const searchStrLower = searchStr.toLowerCase();
    const startsWith: ExerciseOption[] = [];
    const theRest: ExerciseOption[] = [];

    this.exerciseOptions.forEach(x => {
      const pos = x.lower.indexOf(searchStrLower);

      if (pos === 0) {
        startsWith.push(x);
      } else if (pos > 0) {
        theRest.push(x);
      }
    });

    const compareFunc = (a: ExerciseOption, b: ExerciseOption): number => {
      if (a.lower < b.lower) {
        return -1;
      } else if (a.lower > b.lower) {
        return 1;
      } else {
        return 0;
      }
    };

    startsWith.sort(compareFunc);
    theRest.sort(compareFunc);

    cb(startsWith.concat(theRest));
  }

  private handleExerciseSelect(exerciseOption: ExerciseOption): void {
    this.movementTypeSetup.exerciseId = exerciseOption.key;

    this.setLastUsed();
  }

  /**
   * This is here to make sure that we default the wrapper value to the selected exercise
   */
  private handleExerciseBlur(): void {
    const selected = this.exerciseOptions.find(x => x.key === this.movementTypeSetup.exerciseId) as ExerciseOption;

    if (!selected) {
      this.selectedExerciseWrapper = '';
    } else if (selected.value !== this.selectedExerciseWrapper) {
      this.selectedExerciseWrapper = selected.value;
    }
  }

  private async setLastUsed(): Promise<void> {
    this.lastUsed = '';

    if (this.movementTypeSetup && this.movementTypeSetup.exerciseId) {
      this.lastUsed = '...';

      const usedInWorkouts: WorkoutDocument[] = await WorkoutModel.find({
        workoutBrandId: { eq: this.workout.workoutBrandId },
        searchExerciseList: { contains: this.movementTypeSetup.exerciseId },
      });

      if (usedInWorkouts.length > 0) {
        usedInWorkouts.sort((a, b) => a.sequence - b.sequence);

        const sequence: number[] = usedInWorkouts.map(x => x.sequence);
        const lastSequence: number = sequence.splice(-1, 1).pop() as number;

        this.lastUsed = `Last used in workout ${sequence.length > 0 ? sequence.join(', ') + ' and ' : ''}${lastSequence}`;
      } else {
        this.lastUsed = 'Never used';
      }
    }
  }
}
