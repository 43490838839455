























































import { Component, Vue } from 'vue-property-decorator';
import { CategoryType, CategoryTypeList, ProductType, ProductTypeList } from '@/constants';
import BrandStore from '@/store/modules/brand';
import WorkoutStore from '@/store/modules/workout';
import ExerciseStore from '@/store/modules/exercise';
import { BrandDocument, WorkoutDocument, ExerciseDocument } from '@/models';
import { IWorkoutBrandProductType, IWorkoutBrandProductTypeExercise } from '@/models/workout';
import { routeHref } from '@/util/helpers';

interface IWorkoutOverviewSearch {
  categoryList: CategoryType[];
  brandList: string[];
  workoutSequence: number;
  productType: ProductType;
}

interface IWorkoutOverviewSourceWorkoutSetup {
  sequence: number;
  exerciseSetup: IWorkoutBrandProductTypeExercise[];
}

type IWorkoutOverviewSourceBrandSetup = Record<string, IWorkoutOverviewSourceWorkoutSetup[]>;

type IWorkoutOverviewSource = {
  [value in ProductType]: IWorkoutOverviewSourceBrandSetup;
};

@Component({
  name: 'WorkoutOverview',
})
export default class extends Vue {
  private isSearching: boolean = false;
  private source: IWorkoutOverviewSource = (null as unknown) as IWorkoutOverviewSource;
  private exerciseMap: Record<string, ExerciseDocument> = (null as unknown) as Record<string, ExerciseDocument>;
  private results: any[] = [];
  private categoryList: Record<CategoryType, string> = (null as unknown) as Record<CategoryType, string>;
  private brandList: BrandDocument[] = (null as unknown) as BrandDocument[];
  private productTypeList: Record<ProductType, string> = (null as unknown) as Record<ProductType, string>;
  private maxWorkoutSequence: number = (null as unknown) as number;
  private search: IWorkoutOverviewSearch = {
    categoryList: [],
    brandList: [],
    workoutSequence: 1,
    productType: ProductType.SIGNATURE_PRO,
  };

  public created() {
    this.categoryList = CategoryTypeList;
    this.brandList = BrandStore.list;
    this.maxWorkoutSequence = WorkoutStore.list.reduce((accumulator, workout) => Math.max(accumulator, workout.sequence), 1);
    this.productTypeList = ProductTypeList;

    /**
     * We already have the search data, so just reformat it into a structure that makes it easier to get info from
     */
    this.source = Object.keys(ProductType).reduce(
      (accumulator, productType) => ({
        ...accumulator,
        [productType]: this.brandList.reduce((brandAccumulator, brand) => ({ ...brandAccumulator, [brand.id]: [] }), {} as IWorkoutOverviewSourceBrandSetup),
      }),
      {} as IWorkoutOverviewSource,
    );

    WorkoutStore.list.forEach(workout => {
      workout.productTypeWorkouts.forEach(productTypeWorkout => {
        this.source[productTypeWorkout.productType][workout.workoutBrandId].push({
          sequence: workout.sequence,
          exerciseSetup: productTypeWorkout.exerciseSetup,
        });
      });
    });

    /**
     * Create an exercise map to map it easier
     */
    this.exerciseMap = ExerciseStore.list.reduce(
      (accumulator, exercise) => ({ ...accumulator, [exercise.id]: exercise }),
      {} as Record<string, ExerciseDocument>,
    );
  }

  public mounted() {
    this.doSearch();
  }

  private getVisibleCategories(): Record<CategoryType, string> {
    return (Object.keys(CategoryType) as CategoryType[]).reduce((accumulator, categoryType) => {
      const selectedBrands: BrandDocument[] = this.getVisibleBrands(categoryType);

      if (selectedBrands.length > 0) {
        accumulator[categoryType] = CategoryTypeList[categoryType];
      }

      return accumulator;
    }, {} as Record<CategoryType, string>);
  }

  private getVisibleBrands(categoryType: CategoryType): BrandDocument[] {
    return this.brandList.filter(brand => {
      if (brand.category !== categoryType) {
        return false;
      }

      if (this.search.brandList.length > 0) {
        return this.search.brandList.includes(brand.id);
      } else if (this.search.categoryList.length > 0) {
        return this.search.categoryList.includes(brand.category);
      }

      return true;
    });
  }

  private getSelectedCategories(): Record<CategoryType, string> {
    let categories: CategoryType[] = this.search.categoryList;

    if (categories.length === 0) {
      categories = Object.keys(CategoryType) as CategoryType[];
    }

    return categories.reduce(
      (accumulator, categoryType) => ({ ...accumulator, [categoryType]: CategoryTypeList[categoryType] }),
      {} as Record<CategoryType, string>,
    );
  }

  private getCategoryBrands(categoryType: CategoryType): BrandDocument[] {
    return this.brandList.filter(x => x.category === categoryType);
  }

  private doSearch(): void {
    /**
     * Filter the workouts based on the compiled brands
     */
    let searchBrands: BrandDocument[] = [];

    if (this.search.brandList.length > 0) {
      searchBrands = this.brandList.filter(x => this.search.brandList.includes(x.id));
    } else if (this.search.categoryList.length > 0) {
      searchBrands = this.brandList.filter(x => this.search.categoryList.includes(x.category));
    } else {
      searchBrands = this.brandList;
    }

    const searchBrandIdList = searchBrands.map(x => x.id);
    const workouts: WorkoutDocument[] = WorkoutStore.list.filter(workout => searchBrandIdList.includes(workout.workoutBrandId));

    /**
     * Find the max movement type length on that product type
     */
    const maxMovementTypeLength = workouts.reduce(
      (accumulator, workout) =>
        Math.max(
          accumulator,
          (workout.productTypeWorkouts.find(x => x.productType === this.search.productType) as IWorkoutBrandProductType).exerciseSetup.length,
        ),
      0,
    );

    this.results = [];

    for (let movementTypeIndex = 0; movementTypeIndex < maxMovementTypeLength; movementTypeIndex++) {
      this.results.push(
        searchBrandIdList.reduce(
          (accumulator, brandId) => {
            let exercise: ExerciseDocument | null = null;

            const workoutSetup: IWorkoutOverviewSourceWorkoutSetup = this.source[this.search.productType][brandId].find(
              x => x.sequence === this.search.workoutSequence,
            ) as IWorkoutOverviewSourceWorkoutSetup;

            if (workoutSetup) {
              const exerciseSetup: IWorkoutBrandProductTypeExercise = workoutSetup.exerciseSetup[movementTypeIndex];

              if (exerciseSetup && this.exerciseMap[exerciseSetup.exerciseId]) {
                exercise = this.exerciseMap[exerciseSetup.exerciseId];
              }
            }

            return { ...accumulator, [brandId]: exercise };
          },
          { exerciseSequence: movementTypeIndex + 1 } as any,
        ),
      );
    }
  }

  protected gotoExercise(exercise: ExerciseDocument): void {
    const href = routeHref({
      name: 'exercise-update',
      params: { id: exercise.id },
    });

    window.open(href, '_blank');
  }
}
